import { createSlice } from "@reduxjs/toolkit";
import { loginRequest, graphConfig, msalConfig } from "shared/libs/azure-msal";
import { PublicClientApplication } from "@azure/msal-browser";

const initialState = {
  isAuthenticated: false,
  userInfo: null,
  token: null,
};

export const msalInstance = new PublicClientApplication(msalConfig);

const slice = createSlice({
  //   baseQuery: msalInstance.getActiveAccount(),
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setCredentials: (state, { payload: { userInfo, token, refreshToken } }) => {
      state.userInfo = userInfo;
      state.token = token;
    },
  },
});
export const { setCredentials, setAuthenticated, setUser } = slice.actions;

export default slice.reducer;

export const authReducer = state => state.auth.user;
