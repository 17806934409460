import { Grid } from "@mui/material";
import { DatePickerField, DropDown, InputTextField } from "components/UI";
import { FORM_DEFAULT_TRANSACTION_RAW_DATA } from "pages/Report/constants/constants";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import {
  useReportCommonGetRPackageOwnerQuery,
  useReportCommonGetRPackageTypeQuery,
  useReportCommonGetLocationNameListQuery,
  useReportGetTransactionModeQuery,
  useReportGetTransactionCategoryQuery,
  useExcelDownloadOnDemandMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { responseDownloadFileErrors } from "utils";

import { useLoadingContext } from "shared/contexts/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
export default function TransactionRawDataReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_TRANSACTION_RAW_DATA);

  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery();
  const { data: rPkgTypeList, failureReason: rPkgTypeFailureReason } = useReportCommonGetRPackageTypeQuery({
    rPackageOwner: userProfile.dataOwner,
  });
  const { data: locationFromToList, failureReason: locationFailureReason } = useReportCommonGetLocationNameListQuery({
    dataOwner: userProfile.dataOwner,
  });
  const { data: transactionModeList, failureReason: transactionModeFailureReason } = useReportGetTransactionModeQuery();
  const { data: transactionCategoryList, failureReason: transactionCategoryFailureReason } =
    useReportGetTransactionCategoryQuery();

  useEffect(() => {
    let message = [];
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgTypeFailureReason?.response?.status >= 404) {
      message.push(`R-Package Type List: ${ResponseText[rPkgTypeFailureReason?.response?.status]}`);
    }
    if (locationFailureReason?.response?.status >= 404) {
      message.push(`Location From-To List: ${ResponseText[locationFailureReason?.response?.status]}`);
    }
    if (transactionModeFailureReason?.response?.status >= 404) {
      message.push(`Transaction Mode List: ${ResponseText[transactionModeFailureReason?.response?.status]}`);
    }
    if (transactionCategoryFailureReason?.response?.status >= 404) {
      message.push(`Transaction Category List: ${ResponseText[transactionCategoryFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
  }, [
    rPkgOwnerFailureReason?.response?.status,
    rPkgTypeFailureReason?.response?.status,
    locationFailureReason?.response?.status,
    transactionModeFailureReason?.response?.status,
    transactionCategoryFailureReason?.response?.status,
    props,
  ]);

  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const generateReport = useExcelDownloadOnDemandMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      setDisabledSubmitBtn(true);
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        userId: userProfile.userId,
        displayName: userProfile.displayName,
        dataOwner: userProfile.dataOwner,
        transactionDateFrom: form?.transactionDateFrom,
        transactionDateTo: form?.transactionDateTo,
        rPackageType: form?.rPkgType,
        barcodeId: form?.barcodeId,
        locationFrom: form?.locationFrom,
        locationTo: form?.locationTo,
        rPackageOwner: form?.rPkgTypeOwner,
        transactionMode: form?.transactionMode,
        transactionCategory: form?.transactionCategory,
      };
      const pathReport = "generate-transaction-raw-data-report";
      // const fileName = `Transaction_Raw_Data_${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      startLoading();
      const response = await generateReport(body, pathReport);
      stopLoading();
      props.setMsgAlert([response?.message]);
      setDisabledSubmitBtn(false);
      return;
    } catch (error) {
      if (error?.response?.status >= 500) {
        props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseDownloadFileErrors(error);
        props.setMsgError(msgError);
      }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  useEffect(() => {
    document.getElementById("input-transaction-date-from").focus();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Report : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[
              {
                key: "LDN960F0",
                value: "Transaction Raw Data Report",
              },
            ]}
            value={"LDN960F0"}
            disabled
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Transaction Date (From) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input-transaction-date-from"
            className="input-transaction-date-from"
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionDateFrom: e,
              }))
            }
            value={form?.transactionDateFrom}
            // disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Transaction Date (To): </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input-transaction-date-to"
            className="input-transaction-date-to"
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionDateTo: e,
              }))
            }
            value={form?.transactionDateTo}
            // disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            fullWidth
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>R-Package Owner : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val?.rPkgOwnerCompAbbr,
              value: val?.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>R-Package Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgTypeList?.map(val => ({
              key: val?.rPkgTypeId,
              value: `${val?.rPkgType}:${val?.rPkgTypeId}`,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form?.rPkgTypeOwner.length}
          />
        </Grid>
      </Grid>

      {/* Container item #4 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Transaction Mode : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-transaction-mode"
            fullWidth
            value={form?.transactionMode}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={transactionModeList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionMode: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Transaction Category : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-transaction-category"
            fullWidth
            value={form?.transactionCategory}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={transactionCategoryList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionCategory: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #5 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 1 }}>
        <Grid item xs={2}>
          <strong>Location (From) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-location-from"
            fullWidth
            value={form?.locationFrom}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={locationFromToList?.map(val => ({
              key: val?.cd,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationFrom: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Location (To) : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-location-to"
            fullWidth
            value={form?.locationTo}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={locationFromToList?.map(val => ({
              key: val?.cd,
              value: val?.name,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationTo: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #6 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Barcode ID : </strong>
        </Grid>
        <Grid item xs={3}>
          <InputTextField
            id="input-barcode-id"
            fullWidth={true}
            maxLength={14}
            value={form?.barcodeId}
            // regularExp={/^[a-zA-Z0-9_*-]*$/}
            onChange={e =>
              setForm(old => ({
                ...old,
                barcodeId: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_TRANSACTION_RAW_DATA}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
        }}
      />
    </Grid>
  );
}
