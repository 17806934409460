import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { InputButton } from "components/UI";
import { GridRowModes } from "@mui/x-data-grid";
//UTIL
import { findObject, responseErrors } from "utils";
import { BUTTON_VALUE } from "shared/constants";
// Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { ModeAction } from "state/enum";
import { simulateGraph } from "../hooks/format";
//Service
import { useWareHouseSummarySearchGraphMutation } from "shared/services/inventory";
export default function ButtonGraph(props) {
  const {
    form,
    mode,
    rows,
    graphData,
    defaultGraphData,
    setGraph,
    setMode,
    getGraph,
    onSearch,
    setOnSearch,
    setOpenGraph,
    setDefaultGraph,
    getWarehouse,
    rPkg,
    setOnSimulate,
    loading: { startLoading, stopLoading },
    stateBtn: { simulateBtn, cancelBtn },
    setMsg: { setMsgAlert, setMsgError },
    dataList: { rPackageOwnerList, rPackageTypeList, wareHouseList },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
  } = props;
  const confirmDialogCtx = useConfirmDialogContext();
  const searchGraph = useWareHouseSummarySearchGraphMutation();
  const handleSimulate = useCallback(async () => {
    try {
      setMsgAlert([]);
      setMsgError([]);

      if (!defaultGraphData.length) {
        const bodySearch = {
          dataOwner: form?.dataOwner,
          rPkgOwner: !form?.rPackageOwnerId
            ? ""
            : findObject({
                data: rPackageOwnerList,
                value: form?.rPackageOwnerId,
                property: "rPkgOwnerCompId",
                field: "rPkgOwnerCompAbbr",
              }),
          rPkgType: !form?.rPackageTypeId
            ? ""
            : findObject({
                data: rPackageTypeList,
                value: form?.rPackageTypeId,
                property: "rPkgTypeId",
                field: "rPkgType",
              }),
          location: getWarehouse(form?.wareHouseId),
        };
        startLoading();
        const data = await searchGraph(bodySearch);
        stopLoading();
        setGraph(data.result);
        setDefaultGraph(data?.result);
      }
      const simulateData = simulateGraph(rowSelectionModel, defaultGraphData, rPkg);
      setGraph(simulateData);
      // setOnSimulate(true);
      // console.log("handleSimulate", rowSelectionModel, uniq(rowSelectionModel));
      // //tiger table mode edit
      // let tempRowModesModel = rowModesModel;

      // const rowSelect = rows?.map(val => {
      //   if (rowSelectionModel.includes(val?.rowNumber))
      //     tempRowModesModel = {
      //       ...tempRowModesModel,
      //       [val?.rowNumber]: { mode: GridRowModes.View },
      //     };
      // });

      // rowSelect && setRowModesModel(tempRowModesModel);
      setOpenGraph(true);
      return;
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  }, [defaultGraphData, rPkg, rowSelectionModel, setGraph, setMsgAlert, setMsgError, setOpenGraph]);

  const handleCancel = async () => {
    try {
      //reset
      setMsgAlert([]);
      setMsgError([]);
      const allocateConfirm = await confirmDialogCtx.success({
        type: "INVENTORY_CONFIRM_CANCEL",
      });
      if (!allocateConfirm) {
        return;
      }
      // let tempRowModesModel = rowModesModel;

      // rowSelectionModel.forEach(val => {
      //   tempRowModesModel = {
      //     ...tempRowModesModel,
      //     [val]: { mode: GridRowModes.View, ignoreModifications: true },
      //   };
      // });

      // setRowModesModel(tempRowModesModel);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRowModesModel({});
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  return (
    <Box>
      <InputButton id="button-simulate" value={"Simulate"} disabled={simulateBtn} onClick={handleSimulate} />
      <InputButton id="button-cancel" value={BUTTON_VALUE.CANCEL} disabled={cancelBtn} onClick={handleCancel} />
    </Box>
  );
}
