// Service
import { useRPackageGroupCodeGetMutation, useContainerGetActualRPkgMutation } from "shared/services/invoice";
import { GROUP_CODE_TYPE } from "../constants/constant";
import { COMMON_ENUM } from "shared/constants";

export default class SumQtyRPackage {
  getGroupCdRPkg = useRPackageGroupCodeGetMutation();
  actualRPkg = useContainerGetActualRPkgMutation();
  constructor() {
    this.sumPlanRPkgQty = this.sumPlanRPkgQty.bind(this); // Bind the method to the class instance
    this.sumActualQty = this.sumActualQty.bind(this); // Bind the method to the class instance
    this.checkGroup = this.checkGroup.bind(this); // Bind the method to the class instance
  }
  sumPlanRPkgQty = async (rPkgList, containerList) => {
    try {
      for (const index in containerList) {
        const row = containerList[index];
        for (let container of rPkgList) {
          let rmQty = 0;
          let otQty = 0;
          if (row?.rowNumber === container?.rowNumber) {
            for (const index in container?.rPackage) {
              const rPkg = container?.rPackage[index];
              let groupCd = rPkg?.groupCd;
              if (!rPkg?.groupCd) {
                console.log("rPkgType", rPkg?.rPkgType);

                groupCd = await this.checkGroup(rPkg?.rPkgType);
              }

              if (groupCd === GROUP_CODE_TYPE.R_MODULE) {
                rmQty += Number(rPkg?.planQty);
              } else {
                otQty += Number(rPkg?.planQty);
              }
            }

            if (Number(container["planRModuleQty"]) !== rmQty || Number(container["planRBoxDunQty"]) !== otQty) {
              container["planRModuleQty"] = rmQty;
              container["planRBoxDunQty"] = otQty;
            }
          }
        }
      }
    } catch (error) {
      console.error("Error calRPkgQty", error);
    }
  };
  // Check Group CD of R-Package
  checkGroup = async rPkgType => {
    try {
      const { result: groupCd } = await this.getGroupCdRPkg(rPkgType);
      return groupCd;
    } catch (error) {
      console.error("Error checkGroup", error);
    }
  };

  sumActualQty = async row => {
    try {
      let sumRMQty = 0;
      let sumOTQty = 0;
      if (!row?.vanningInfoHId || !row) {
        console.log("no vanningInfoHId or row");
        return { sumRMQty, sumOTQty };
      }
      const actualRPkgList = await this.actualRPkg({
        vanningInfoHId: row?.vanningInfoHId,
        planContainerNo: row?.actualContainerNo,
      });

      if (!actualRPkgList?.result?.length) {
        return { sumRMQty, sumOTQty };
      } else {
        console.log("has data actualRPkgList");
        actualRPkgList?.result?.forEach(async rPkg => {
          if (rPkg?.groupCd === COMMON_ENUM.C_R_MODULE) {
            sumRMQty = Number(sumRMQty) + Number(rPkg?.qty);
          } else {
            sumOTQty = Number(sumOTQty) + Number(rPkg?.qty);
          }
        });
      }
      return { sumRMQty, sumOTQty };
    } catch (error) {
      console.error("sumActualQty", error);
      return { sumRMQty: 0, sumOTQty: 0 };
    }
  };
}
