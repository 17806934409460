import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
import { InputButton } from "components/UI";
import { ModeAction } from "state/enum";
import { useConfirmDialogContext } from "context/confirmDialogContext";
export default function ButtonSubmit({
  mode,
  rows,
  setMode,
  setRows,
  setRowModesModel,
  setMsgError,
  rowModesModel,
  rowSelectionModel,
  setRowSelectionModel,
  setOnSearch,
  isRPkg = false,
}) {
  const confirmDialogCtx = useConfirmDialogContext();

  useMemo(() => mode, [mode]);

  const handleSaveClick = async () => {
    try {
      const saveConfirm = await confirmDialogCtx.success({ type: "confirmSave" });
      if (!saveConfirm) {
        return;
      }
      if (mode === ModeAction.ADD) {
        //To find maximum rowNumber
        const latestKey = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

        setRowModesModel({
          ...rowModesModel,
          [latestKey]: { mode: GridRowModes.View },
        });
        return;
      } else {
        let tempRowModesModel = rowModesModel;
        const rowSelect = rowSelectionModel.map(val => {
          tempRowModesModel = {
            ...tempRowModesModel,
            [val]: { mode: GridRowModes.View },
          };
          // return tempRowModesModel;
        });

        rowSelect && setRowModesModel(tempRowModesModel);
        return;
      }
    } catch (error) {
      setMsgError([error]);
    }
  };
  const handleCancelClick = async () => {
    const confirm = await confirmDialogCtx.success({ type: "confirmCancel" });
    if (!confirm) {
      return;
    }

    setMsgError([]);
    if (mode === ModeAction.ADD) {
      //To find maximum rowNumber
      const latestKey = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));

      setRowModesModel({
        ...rowModesModel,
        [latestKey]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      setRows(rows.filter(row => !row.isNew)); //Keep only not new
    } else {
      let tempRowModesModel = rowModesModel;

      const rowSelect = rowSelectionModel.map(val => {
        tempRowModesModel = {
          ...tempRowModesModel,
          [val]: { mode: GridRowModes.View, ignoreModifications: true },
        };
      });

      rowSelect && setRowModesModel(tempRowModesModel);
    }

    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
  };

  return (
    <>
      {[ModeAction.ADD, ModeAction.EDIT, ModeAction.COPY].includes(mode) && (
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <InputButton id="btn_save" value={`${isRPkg ? "Confirm" : "Confirm Plan"}`} onClick={handleSaveClick} />
          <InputButton id="btn_cancel" value={"Cancel"} onClick={handleCancelClick} />
        </Grid>
      )}
    </>
  );
}
