import React, { Fragment } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Footer } from "components/Layout";
export default function PopupDialog({
  id,
  onOpen,
  title,
  content,
  action,
  maxWidth = "md",
  onFooter = false,
  rest,
}) {
  return (
    <Fragment>
      <Dialog
        id={`popup-${id}`}
        open={onOpen}
        fullWidth
        maxWidth={maxWidth}
        sx={{ height: "100vh" }}
        {...rest}
      >
        {title && <DialogTitle children={title} />}
        <DialogContent
          children={content}
          sx={{ padding: "12px 12px" }}
        ></DialogContent>
        {action && <DialogActions children={action}></DialogActions>}
        {onFooter && (
          <Box sx={{ mt: 4, position: "sticky" }}>
            <Footer width="100%" />
          </Box>
        )}
      </Dialog>
    </Fragment>
  );
}
