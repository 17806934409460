import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import TanStackQueryProvider from "shared/libs/reactQuery/ReactQueryProvider";
import { LoadingProvider } from "shared/contexts/LoadingContext";
import { initConfigDate } from "@polygon/backdate-config";
import { EventType } from "@azure/msal-browser";
import { msalInstance } from "shared/libs/azure-msal";
import { Provider } from "react-redux";
import store from "shared/stores/store";
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}
// Listen for sign-in event and set active account
msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
initConfigDate(process.env.REACT_APP_CONFIG_DATE);
root.render(
  <LoadingProvider>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={10}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        style={{ background: "#f9ffb1", color: "black" }}>
        <Provider store={store}>
          {/* <AuthProvider> */}
          <TanStackQueryProvider>
            <App instance={msalInstance} />
          </TanStackQueryProvider>
          {/* </AuthProvider> */}
        </Provider>
      </SnackbarProvider>
    </ThemeProvider>
  </LoadingProvider>
);
