export const API_INVOICE_SHIPPING_INFO = {
  ROW_NUMBER: "rowNumber",
  EXPORTER_CODE: "exporterCode",
  EXP_CODE: "expCd",
  EXPORTER_COUNTRY: "expCountry",
  EXPORTER_COUNTRY_CODE: "expCountryCd",
  IMPORTER_CODE: "importerCode",
  IMP_CODE: "impCd",
  IMPORTER_COMPANY: "impCompanyAbbr",
  IMPORTER_COMPANY_ID: "impCompanyAbbrId",
  ETD_DATE: "etd",
  CONSIGNEE: "consignee",
  CONSIGNEE_ID: "consigneeId",
  LOADING_PORT: "loadingPort",
  DISCHARGING_PORT: "dischargingPort",
  VESSEL_NAME: "vesselName",
  BROKER: "broker",
  BROKER_ID: "brokerId",
  CAR_FAMILY_CODE: "carFamilyCode",
  INV_STATUS: "invoiceStatus",
  CONTAINER_DETAIL: "containerDetail",
  ORDER_TYPE: "orderType",
  ORDER_TYPE_CODE: "orderTypeCd",
  VANNING_STATUS: "vanningStatus",
  AIR_SHIPMENT: "airShipment",
  SHIPPING_INFO_H_ID: "shippingInfoHId",
  VANNING_INFO_H_ID: "vanningInfoHId",
  UPDATE_DATE: "updateDt",
  UPDATE_BY: "updateBy",
  SHIPPING_SESSION_ID: "shippingSessionId",
  CONT_SESSION_ID: "contSessionId",
  R_PKG_SESSION_ID: "rPkgSessionId",

  // Container Sub screen
  PLAN_CONTAINER_NO: "planContainerNo",
  PLAN_RM_QTY: "planRModuleQty",
  PLAN_OT_QTY: "planRBoxDunQty",
  PLAN_DETAIL_BTN: "planRPkgDetail",
  ACTUAL_CONTAINER_NO: "actualContainerNo",
  ACTUAL_RM_QTY: "actualRModuleQty",
  ACTUAL_OT_QTY: "actualRBoxDunQty",
  ACTUAL_DETAIL_BTN: "actualRPkgDetail",
  RENBAN_NO: "renbanNo",
  ACTUAL_PACKING_MONTH: "actualPackingMonth",
  ACTUAL_VANNING_PLANT: "actualVanningPLant",
  ACTUAL_ORDER_TYPE: "actualOrderType",
  CONTAINER_STATUS: "status",
  SHIPPING_INFO_D_CONT_ID: "shippingInfoDContId",

  //R-PACKAGE Sub
  R_PKG_OWNER: "rPkgOwner",
  R_PKG_OWNER_ID: "rPkgOwnerId",
  R_PKG_TYPE_ID: "rPkgTypeId",
  R_PKG_TYPE: "rPkgType",
  R_PKG_PLAN_QTY: "planQty",
  R_PKG_ACTUAL_QTY: "actQty",
  GROUP_CD: "groupCd",
  SHIPPING_INFO_D_PKG_ID: "shippingInfoDPkgId",
};
