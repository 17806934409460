import React from "react";
import { Divider, Grid } from "@mui/material";
import { InputButton } from "components/UI";
import { GridRowModes } from "@mui/x-data-grid";
import LabelGraph from "./LabelGraph";
//UTIL
import { findObject, messageDisplay, responseErrors } from "utils";
import { validationSearchForm } from "utils/validation";
//Hook
import sleep from "shared/hooks/Sleep";
//Type
import { BUTTON_VALUE } from "shared/constants";
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { formatRowWareHouseSummary } from "../hooks/format";
import { DEFAULT_WAREHOUSE_SUMMARY } from "../constants/constant";
//Service
import {
  useWareHouseSummarySearchTableMutation,
  useWareHouseSummarySearchGraphMutation,
} from "shared/services/inventory";

export default function ButtonCriterial(props) {
  const {
    form,
    setForm,
    rows,
    setRows,
    setRPkg,
    setGraph,
    graphData,
    defaultGraphData,
    setDefaultGraph,
    setMode,
    onSearch,
    setOnSearch,
    getWarehouse,
    setOpenGraph,
    getGraph,
    setOnSimulate,
    stateBtn: { searchBtn, clearBtn, editBtn, viewBtn },
    setMsg: { setMsgAlert, setMsgError },
    loading: { startLoading, stopLoading },
    dataList: { rPackageOwnerList, rPackageTypeList, wareHouseList },
    rowSelection: { rowModesModel, setRowModesModel, setRowSelectionModel, rowSelectionModel },
  } = props;
  const searchTable = useWareHouseSummarySearchTableMutation();
  const searchGraph = useWareHouseSummarySearchGraphMutation();
  const handleView = async e => {
    try {
      e.preventDefault();

      setMode(ModeAction.VIEW);
      setMsgError([]);
      setMsgAlert([]);
      if (defaultGraphData.length > 0) {
        setOpenGraph(true);
        return;
      }

      setGraph([]);
      setDefaultGraph([]);
      const bodySearch = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        location: getWarehouse(form?.wareHouseId),
      };
      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const data = await searchGraph(bodySearch);
      // const searchGraph = await getGraph(bodySearch);
      stopLoading();

      setGraph(data.result);
      setDefaultGraph(data?.result);
      setOpenGraph(true);
      setOnSearch(true);
    } catch (error) {
      if (error?.statusCode === 500) {
        return setMsgError([error?.message]);
      }
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      stopLoading();
      return;
    }
  };
  const handleSearch = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setGraph([]);
      setDefaultGraph([]);
      // Reset Data of Table
      setOnSearch(false);
      setMode("Initial");
      setRowSelectionModel([]);
      setRowModesModel({});
      setRows([]);

      const bodySearch = {
        dataOwner: form?.dataOwner,
        rPkgOwner: !form?.rPackageOwnerId
          ? ""
          : findObject({
              data: rPackageOwnerList,
              value: form?.rPackageOwnerId,
              property: "rPkgOwnerCompId",
              field: "rPkgOwnerCompAbbr",
            }),
        rPkgType: !form?.rPackageTypeId
          ? ""
          : findObject({
              data: rPackageTypeList,
              value: form?.rPackageTypeId,
              property: "rPkgTypeId",
              field: "rPkgType",
            }),
        warehouse: getWarehouse(form?.wareHouseId),
      };
      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: "rPkgOwner",
            type: MessageType.EMPTY,
            key: ["R-Package Owner"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchTable(bodySearch);
      stopLoading();
      if (!searchData?.result?.length) {
        setMsgError([
          messageDisplay({
            type: MSG_TYPE.ERROR,
            code: "MSTD0059AERR",
            msg: "",
          }),
        ]);
        return;
      }
      // const searchGraph = await getGraph(bodySearch);
      const { data, rPkg } = formatRowWareHouseSummary(searchData?.result);
      setRows(data);
      setRPkg(rPkg);
      // setGraph(searchGraph.result);
      // setDefaultGraph(searchGraph?.result);
      setMode(ModeAction.VIEW);
      setOnSearch(true);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      stopLoading();
      return;
    }
  };

  const handleClear = async () => {
    setMsgError([]);
    setMsgAlert([]);
    // reset form
    setForm(DEFAULT_WAREHOUSE_SUMMARY);
    setMode("Initial");
    setOnSearch(false);
    setRowModesModel({});
    setRowSelectionModel([]);
    setRows([]);
    setRPkg([]);
    setOnSimulate(false);
    // setPagination({});
    // setPageNumber(1);
  };
  const handleEdit = async () => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      //tiger table mode edit
      setMode(ModeAction.EDIT);
      // let tempRowModesModel = rowModesModel;
      // const rowSelect = rows?.map(val => {
      //   return (tempRowModesModel = {
      //     ...tempRowModesModel,
      //     [val.rowNumber]: { mode: GridRowModes.Edit },
      //   });
      // });
      // if (rowSelect) {
      //   setRowModesModel(tempRowModesModel);
      // }
      return;
    } catch (error) {
      console.error(error);
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  return (
    <Grid item sx={{ m: "2px 0" }}>
      <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end", mt: 2, mb: 2 }}>
        <InputButton id="button-view" value={BUTTON_VALUE.VIEW} onClick={handleView} disabled={viewBtn} />
        <InputButton id="button-search" value={BUTTON_VALUE.SEARCH} onClick={handleSearch} disabled={searchBtn} />
        <InputButton id="button-clear" value={BUTTON_VALUE.CLEAR} onClick={handleClear} disabled={clearBtn} />
        <InputButton id="button-edit" value={BUTTON_VALUE.EDIT} onClick={handleEdit} disabled={editBtn} />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
