import { Grid } from "@mui/material";
import { DatePickerField, DropDown } from "components/UI";
import React, { useEffect, useState } from "react";
import ButtonAction from "../ButtonAction";
import { FORM_DEFAULT_AVERAGE_LEAD } from "pages/Report/constants/constants";
import {
  useReportCommonTransactionCategoryListQuery,
  useReportCommonGetReportBasisQuery,
  useReportCommonGetLocationTypeQuery,
  useReportCommonGetLocationGroupQuery,
  useReportCommonGetOperationQuery,
  useReportCommonGetRoutePointQuery,
  useReportCommonGetRPackageStatusFromQuery,
  useReportCommonGetRPackageStatusToQuery,
  useReportCommonGetPackStatusFromQuery,
  useReportCommonGetPackStatusToQuery,
  useExcelDownloadOnlineMutation,
  useReportCommonGetRPackageOwnerQuery96i0,
  useReportCommonGetRPackageTypeQuery96i0,
  useExcelDownloadOnlineJavaMutation,
} from "shared/services/report";
import { userProfile } from "constant";
import { findObject, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
import axios from "axios";
import { getLocalDate } from "utils/init-config-date";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { ResponseText } from "utils/5xxResponseText";
export default function AverageLeadTimeReport(props) {
  const [form, setForm] = useState(FORM_DEFAULT_AVERAGE_LEAD);
  //API
  const { data: rPkgOwnerList, failureReason: rPkgOwnerFailureReason } = useReportCommonGetRPackageOwnerQuery96i0();
  const { data: rPkgTypeList, failureReason: rPkgTypeFailureReason } = useReportCommonGetRPackageTypeQuery96i0({
    rPackageOwner: "TMATH",
  });
  const { data: transactionCategoryList, failureReason: transactionCategoryFailureReason } =
    useReportCommonTransactionCategoryListQuery({ reportId: "LDN960F0" });
  const { data: reportBasisList, failureReason: reportBasisFailureReason } = useReportCommonGetReportBasisQuery();
  const { data: locationTypeList, failureReason: locationTypeFailureReason } = useReportCommonGetLocationTypeQuery();
  const {
    data: locationGroupList,
    refetch: locationGroupRefetch,
    failureReason: locationGroupFailureReason,
  } = useReportCommonGetLocationGroupQuery({
    locationType: form?.locationType,
  });
  const {
    data: operationList,
    refetch: operationRefetch,
    failureReason: operationFailureReason,
  } = useReportCommonGetOperationQuery({
    dataOwner: "TMATH",
    locationGroup: form?.locationGroup,
    locationType: form?.locationType,
  });
  const {
    data: routePointList,
    refetch: routePointRefetch,
    failureReason: routePointFailureReason,
  } = useReportCommonGetRoutePointQuery({
    dataOwner: "TMATH",
    locationGroup: form?.locationGroup,
    locationType: form?.locationType,
    operationDesc: form?.operation,
  });
  const {
    data: rPkgStatusFromList,
    refetch: rPkgStatusFromRefetch,
    failureReason: rPkgStatusFromFailureReason,
  } = useReportCommonGetRPackageStatusFromQuery({
    dataOwner: "TMATH",
    locationGroup: form?.locationGroup,
    locationType: form?.locationType,
    operationDesc: form?.operation,
  });
  const {
    data: rPkgStatusToList,
    refetch: rPkgStatusToRefetch,
    failureReason: rPkgStatusToFailureReason,
  } = useReportCommonGetRPackageStatusToQuery({
    dataOwner: "TMATH",
    locationGroup: form?.locationGroup,
    locationType: form?.locationType,
    operationDesc: form?.operation,
  });
  const {
    data: packStatusFromList,
    refetch: packStatusFromRefetch,
    failureReason: packStatusFromFailureReason,
  } = useReportCommonGetPackStatusFromQuery({
    dataOwner: "TMATH",
    locationGroup: form?.locationGroup,
    locationType: form?.locationType,
    operationDesc: form?.operation,
  });
  const {
    data: packStatusToList,
    refetch: packStatusToRefetch,
    failureReason: packStatusToFailureReason,
  } = useReportCommonGetPackStatusToQuery({
    dataOwner: "TMATH",
    locationGroup: form?.locationGroup,
    locationType: form?.locationType,
    operationDesc: form?.operation,
  });

  useEffect(() => {
    let message = [];
    if (rPkgOwnerFailureReason?.response?.status >= 404) {
      message.push(`R-Package Owner List: ${ResponseText[rPkgOwnerFailureReason?.response?.status]}`);
    }
    if (rPkgTypeFailureReason?.response?.status >= 404) {
      message.push(`R-Package Type List: ${ResponseText[rPkgTypeFailureReason?.response?.status]}`);
    }
    if (transactionCategoryFailureReason?.response?.status >= 404) {
      message.push(`Transaction Category List: ${ResponseText[transactionCategoryFailureReason?.response?.status]}`);
    }
    if (reportBasisFailureReason?.response?.status >= 404) {
      message.push(`Report Basis List: ${ResponseText[reportBasisFailureReason?.response?.status]}`);
    }
    if (locationTypeFailureReason?.response?.status >= 404) {
      message.push(`Location Type List: ${ResponseText[locationTypeFailureReason?.response?.status]}`);
    }
    if (locationGroupFailureReason?.response?.status >= 404) {
      message.push(`Location Group List: ${ResponseText[locationGroupFailureReason?.response?.status]}`);
    }
    if (operationFailureReason?.response?.status >= 404) {
      message.push(`Operation List: ${ResponseText[operationFailureReason?.response?.status]}`);
    }
    if (routePointFailureReason?.response?.status >= 404) {
      message.push(`Route Point Name List: ${ResponseText[routePointFailureReason?.response?.status]}`);
    }
    if (rPkgStatusFromFailureReason?.response?.status >= 404) {
      message.push(`R-Package Status From List: ${ResponseText[rPkgStatusFromFailureReason?.response?.status]}`);
    }
    if (rPkgStatusToFailureReason?.response?.status >= 404) {
      message.push(`R-Package Status To List: ${ResponseText[rPkgStatusToFailureReason?.response?.status]}`);
    }
    if (packStatusFromFailureReason?.response?.status >= 404) {
      message.push(`Pack Status From List: ${ResponseText[packStatusFromFailureReason?.response?.status]}`);
    }
    if (packStatusToFailureReason?.response?.status >= 404) {
      message.push(`Pack Status To List: ${ResponseText[packStatusToFailureReason?.response?.status]}`);
    }
    props.setMsgServerErrorAlert(message);
    document.getElementById("select-report-basis").focus();
  }, [
    rPkgOwnerFailureReason?.response?.status,
    rPkgTypeFailureReason?.response?.status,
    transactionCategoryFailureReason?.response?.status,
    reportBasisFailureReason?.response?.status,
    locationTypeFailureReason?.response?.status,
    locationGroupFailureReason?.response?.status,
    operationFailureReason?.response?.status,
    routePointFailureReason?.response?.status,
    rPkgStatusFromFailureReason?.response?.status,
    rPkgStatusToFailureReason?.response?.status,
    packStatusFromFailureReason?.response?.status,
    packStatusToFailureReason?.response?.status,
    props,
  ]);

  useEffect(() => {
    locationGroupRefetch();
    operationRefetch();
    routePointRefetch();
    rPkgStatusFromRefetch();
    rPkgStatusToRefetch();
    packStatusFromRefetch();
    packStatusToRefetch();
  }, [form?.locationType]);

  useEffect(() => {
    operationRefetch();
    routePointRefetch();
    rPkgStatusFromRefetch();
    rPkgStatusToRefetch();
    packStatusFromRefetch();
    packStatusToRefetch();
  }, [form?.locationGroup]);

  useEffect(() => {
    routePointRefetch();
    rPkgStatusFromRefetch();
    rPkgStatusToRefetch();
    packStatusFromRefetch();
    packStatusToRefetch();
  }, [form?.operation]);
  const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);

  const generateReport = useExcelDownloadOnlineJavaMutation();
  // const generateReport = useExcelDownloadOnlineMutation();
  const { startLoading, stopLoading } = useLoadingContext();
  const handleDownload = async () => {
    try {
      props.setMsgError([]);
      props.setMsgAlert([]);
      const body = {
        displayName: userProfile.displayName,
        dataOwner: userProfile.dataOwner,
        userId: userProfile.userId,
        reportBasis: form?.reportBasis,
        locationType: form?.locationType,
        locationGroup: form?.locationGroup, //"PTH_1208_000011",
        operation: form?.operation, // "FG TO DLV",
        routePoint: form?.routePointName,
        rPackageStatusFrom: form?.rpkgStatusFrom,
        rPackageStatusTo: form?.rpkgStatusTo,
        packStatusFrom: form?.packStatusFrom,
        packStatusTo: form?.packStatusTo,
        periodFrom: form?.periodFrom, // DD/MM/YYYY
        periodTo: form?.periodTo, // optional DD/MM/YYYY
        rPackageOwner: form?.rPkgTypeOwner,
        rPackageType: form?.rPkgType,
        transactionCategory: form?.transactionCategory,
      };
      const pathReport = "generate-average-lead-time-report";
      // const fileName = `Average_Lead_Time-${getLocalDate().format("YYYYMMDDHHmm")}.xlsx`;
      startLoading();
      const response = await generateReport(body, pathReport);
      stopLoading();
      setDisabledSubmitBtn(false);

      return;
    } catch (error) {
      if (error?.response?.status >= 500) {
        props.setMsgServerErrorAlert([ResponseText[error?.response?.status]]);
      } else {
        const msgError = responseDownloadFileErrors(error);
        props.setMsgError(msgError);
      }
      setDisabledSubmitBtn(false);
      stopLoading();
    }
  };

  return (
    <Grid container spacing={2}>
      {/* Container item #1 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Report : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report"
            fullWidth
            required
            defaultValue=""
            placeholder="<Select>"
            menu={[{ key: "LDN960I0", value: "Average Lead Time Report" }]}
            value={"LDN960I0"}
            // menu={reportTypeList?.map(val => ({
            //   key: val?.cd,
            //   value: val?.value,
            // }))}
            // value={form?.reportId}
            disabled
          />
        </Grid>
      </Grid>

      {/* Container item #2 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Report Basis : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-report-basis"
            fullWidth
            required
            value={form?.reportBasis}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={reportBasisList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                reportBasis: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>*Location Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-location-type"
            fullWidth
            required
            value={form?.locationType}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={locationTypeList?.map(val => ({
              key: val?.cd,
              value: val?.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationType: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #3 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Location Group : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-location-group"
            fullWidth
            required
            value={form?.locationGroup}
            defaultValue=""
            placeholder="<Select>"
            // autoFocus={true}
            menu={locationGroupList?.map((val, index) => ({
              key: val?.locationGroup,
              value: val?.locationGroup,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                locationGroup: e.target.value,
              }))
            }
            disabled={!form?.locationType.length}
          />
        </Grid>
      </Grid>

      {/* Container item #4 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Operation : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-operation"
            fullWidth
            value={form?.operation}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={operationList?.map((val, index) => ({
              key: val?.operationDesc,
              value: val?.operationDesc,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                operation: e.target.value,
              }))
            }
            disabled={!form.locationGroup?.length}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Route Point Name : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-route-point-name"
            fullWidth
            value={form?.routePointName}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={routePointList?.map((val, index) => ({
              key: val?.routePointId,
              value: val?.routePointName,
            }))}
            disabled={!form?.operation.length}
            onChange={e =>
              setForm(old => ({
                ...old,
                routePointName: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #5 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>R-Package Status From : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpkg-status-from"
            fullWidth
            value={form?.rpkgStatusFrom}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgStatusFromList?.map((val, index) => ({
              key: val?.rPkgStsFrom,
              value: val?.rPkgStsFrom,
            }))}
            disabled={!form?.operation.length}
            onChange={e =>
              setForm(old => ({
                ...old,
                rpkgStatusFrom: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>R-Package Status To : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpkg-status-to"
            fullWidth
            value={form?.rpkgStatusTo}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgStatusToList?.map((val, index) => ({
              key: val?.rPkgStsTo,
              value: val?.rPkgStsTo,
            }))}
            disabled={!form?.operation.length}
            onChange={e =>
              setForm(old => ({
                ...old,
                rpkgStatusTo: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #6 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Pack Status From : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-pack-status-from"
            fullWidth
            value={form?.packStatusFrom}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={packStatusFromList?.map((val, index) => ({
              key: val?.packStsFrom,
              value: val?.packStsFrom,
            }))}
            disabled={!form?.operation.length}
            onChange={e =>
              setForm(old => ({
                ...old,
                packStatusFrom: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>Pack Status To : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-pack-status-to"
            fullWidth
            value={form?.packStatusTo}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={packStatusToList?.map((val, index) => ({
              key: val?.packStsTo,
              value: val?.packStsTo,
            }))}
            disabled={!form?.operation.length}
            onChange={e =>
              setForm(old => ({
                ...old,
                packStatusTo: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      {/* Container item #7 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>*Period From : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input-period-from"
            className="input-period-from"
            onChange={e =>
              setForm(old => ({
                ...old,
                periodFrom: e,
              }))
            }
            value={form?.periodFrom}
            // disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>*Period To : </strong>
        </Grid>
        <Grid item xs={3}>
          <DatePickerField
            id="input-period-to"
            className="input-period-to"
            onChange={e =>
              setForm(old => ({
                ...old,
                periodTo: e,
              }))
            }
            value={form?.periodTo}
            // disabled={[ModeAction.ADD, ModeAction.EDIT].includes(mode)}
            fullWidth
            required
          />
        </Grid>
      </Grid>

      {/* Container item #8 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>R-Package Owner : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-owner"
            fullWidth
            value={form?.rPkgTypeOwner}
            defaultValue=""
            placeholder="<All>"
            // autoFocus={true}
            menu={rPkgOwnerList?.map(val => ({
              key: val?.rPkgOwnerCompAbbr,
              value: val?.rPkgOwnerCompAbbr,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgTypeOwner: e.target.value,
              }))
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <strong>R-Package Type : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-rpackage-type"
            fullWidth
            value={form?.rPkgType}
            defaultValue=""
            placeholder="<All>"
            menu={rPkgTypeList?.map(val => ({
              key: val?.rPkgType,
              value: val?.rPkgType,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                rPkgType: e.target.value,
              }))
            }
            disabled={!form?.rPkgTypeOwner.length}
          />
        </Grid>
      </Grid>

      {/* Container item #9 */}
      <Grid container spacing={1} sx={{ alignItems: "center", mb: 2 }}>
        <Grid item xs={2}>
          <strong>Transaction Category : </strong>
        </Grid>
        <Grid item xs={3}>
          <DropDown
            id="select-transaction-category"
            fullWidth
            value={form?.transactionCategory}
            defaultValue=""
            placeholder="<All>"
            menu={transactionCategoryList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            onChange={e =>
              setForm(old => ({
                ...old,
                transactionCategory: e.target.value,
              }))
            }
          />
        </Grid>
      </Grid>

      <ButtonAction
        handleDownload={handleDownload}
        setFrom={setForm}
        defaultFrom={FORM_DEFAULT_AVERAGE_LEAD}
        disabled={disabledSubmitBtn}
        setDisabled={setDisabledSubmitBtn}
        clearMsg={() => {
          props.setMsgAlert([]);
          props.setMsgError([]);
          document.getElementById("select-report-basis").focus();
        }}
      />
    </Grid>
  );
}
