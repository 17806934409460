import React from "react";
import { Box, Grid } from "@mui/material";
import { DataGridTable, InputButton } from "components/UI";
// import SubmitBar from "pages/Partials/SubmitBar";

//Utils
import { findObject, responseErrors, responseSuccess } from "utils";
import { transformSubScreenData } from "../../../hooks/tranformData";
import { validationSearchForm } from "utils/validation";
//Service
import {
  useExportInvoiceCreateDetailVanningResultSubScreenMutation,
  useExportInvoiceEditDetailVanningResultSubScreenMutation,
  useExportInvoiceSearchSubScreenMutation,
} from "shared/services/invoice";
//Type
import { MessageType, ModeAction } from "state/enum";
import { FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { userProfile } from "constant";
// import { API_EXPORT_INVOICE_CREATION_INFO } from "shared/constants/api-name/invoice/exportInvoiceCreation";
import { getLocalDate } from "utils/init-config-date";
import { GridRowModes } from "@mui/x-data-grid";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { DEFAULT_STATE_WDN95011 } from "pages/Invoice/ExportInvoiceCreationMaintenanceScreen/constants/constants";
export default function TableSection(props) {
  const {
    onOpen,
    mode,
    setMode,
    rows,
    setRows,
    columns,
    form,
    setForm,
    setOnSearch,
    refreshRowsTable,
    formRPkg,
    formContainer,
    dataList: { importerList, consigneeList, brokerList },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: {
      rowModesModel,
      setRowModesModel,
      rowSelectionModel,
      setRowSelectionModel,
    },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    setOnOpen,
  } = props;

  const confirmDialogCtx = useConfirmDialogContext();

  const searchExportInfo = useExportInvoiceSearchSubScreenMutation();
  // TODO: API create
  const createSubInvoice =
    useExportInvoiceCreateDetailVanningResultSubScreenMutation();
  const updateSubInvoice =
    useExportInvoiceEditDetailVanningResultSubScreenMutation();

  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        nonCommInvHId: "IVH_2307_000044", // TODO: mock here
        invoiceNo: form?.invNo,
        invoiceOrderType: form?.invType,
        etd: form?.etdDt,
        vesselName: form?.vesselName,
        importerId: form?.impCompanyId,
        importerCompanyAbbr: "TMMIN", // TODO: mock here
        exporterId: form?.expCd ? form?.expCd.split("#")[0] : "",
        exporterCompanyAbbr: "TDEM", // TODO: mock here
        parkingMonth: form?.parkingMonth,
        vanningPlant: form?.vanningPlant,
        brokerId: form?.expBrokerCompanyId,
        loginUserCompanyAbbr: userProfile.dataOwner, // TODO: mock constants here
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const searchData = await searchExportInfo(body);
      const data = transformSubScreenData(searchData?.result?.data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  // TODO: Action Create
  const handleCreate = async (newRow) => {
    // console.log(newRow);
    try {
      let body = {
        // expCd: newRow?.exporterCode,
        // impCompanyId: newRow?.impCompanyAbbrId,
        // impCompanyAbbr: newRow?.impCompanyAbbrId
        //   ? findObject({
        //       data: importerList,
        //       value: newRow?.impCompanyAbbrId,
        //       property: "companyId",
        //       field: "companyAbbr",
        //     })
        //   : "",
        // loadingPort: newRow?.loadingPort,
        // dischargingPort: newRow?.dischargingPort,
        // etd: newRow?.etd,
        // vessel: newRow?.vesselName,
        // consignee: newRow?.consignee
        //   ? findObject({
        //       data: consigneeList,
        //       value: newRow?.consignee,
        //       property: "cd",
        //       field: "value",
        //     })
        //   : "",
        // broker: newRow.broker,
        // brokerAbbr: newRow.broker
        //   ? findObject({
        //       data: brokerList,
        //       value: newRow?.broker,
        //       property: "cd",
        //       field: "value",
        //     })
        //   : "",
        // userId: userProfile?.userId,
        dataOwner: userProfile?.userId,
        invNo: form?.invNo,
        orderTypeCd: "S",
        etdDt: form?.etdDt,
        vesselName: form?.vesselName,
        impCompanyId: form?.impCompanyId,
        expCd: form?.expCd,
        packingMonth: form?.parkingMonth,
        vanningPlant: "G",
        brokerCompanyId: "CMP_2111_000001",
        brokerCompanyAbbr: form?.expBrokerCompanyAbbr,
        expCustomsStkDt: form?.expCustomsStkDt,
        currencyCd: form?.currencyCd,
        expEntryNo: form?.expEntryNo,
        nonCommInvHId: "IVH_2307_000044",
        loginUserCompanyId: "CMP_1208_000001",
        loginUserCompanyAbbr: "TDEM",
        shipmentCd: form?.shipmentCd,
        shipCarierCompanyAbbr: form?.shipCarierCompanyAbbr,
        shipmentSummaryUpdateDt: "",
        loginUser: "test",
        shipment: newRow,
      };

      // const { isSuccess, errors } = validationSearchForm({
      //   data: body,
      //   rule: [
      //     {
      //       field: "expCd",
      //       type: MessageType.EMPTY,
      //       key: ["Exporter Code"],
      //     },
      //     {
      //       field: "impCompanyId",
      //       type: MessageType.EMPTY,
      //       key: ["Importer Company"],
      //     },
      //     {
      //       field: "etd",
      //       type: MessageType.EMPTY,
      //       key: ["ETD", "DD/MM/YYYY"],
      //     },
      //     {
      //       field: "loadingPort",
      //       type: MessageType.EMPTY,
      //       key: ["Loading Port"],
      //     },
      //     {
      //       field: "dischargingPort",
      //       type: MessageType.EMPTY,
      //       key: ["Discharging Port"],
      //     },
      //     {
      //       field: "vessel",
      //       type: MessageType.EMPTY,
      //       key: ["Vessel Name"],
      //     },
      //     {
      //       field: "broker",
      //       type: MessageType.EMPTY,
      //       key: ["Broker"],
      //     },
      //     {
      //       field: "etd",
      //       type: MessageType.DATE_FORMAT_50,
      //       key: ["ETD", "DD/MM/YYYY"],
      //     },
      //     {
      //       field: "etd",
      //       type: MessageType.DATE_EQUAL_LATER,
      //       key: ["ETD", "Current Date", "YYYY-MM-DD"],
      //     },
      //   ],
      // });

      // if (!isSuccess) {
      //   setMsgError(errors);
      //   window.scrollTo(0, 0);
      //   return false;
      // }
      // body.etd = !newRow?.etd
      //   ? ""
      //   : getLocalDate(newRow?.etd, "DD/MM/YYYY").format("YYYY-MM-DD");

      const response = await createSubInvoice(body);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      setForm(DEFAULT_STATE_WDN95011);
      setOnOpen({ open: false });
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };
  // TODO: Action Update
  const handleUpdate = async (row) => {
    try {
      console.log(row);
      const body = {
        userId: userProfile.userId,
        shippingData: {
          expCd: row?.exporterCode,
          impCd: row?.importerCode,
          impCompanyId: row?.impCompanyAbbrId,
          impCompanyAbbr: row?.impCompanyAbbr,
          etd: row?.etd,
          csnCompanyId: row?.consigneeId,
          csnCompanyAbbr: row?.consigneeId
            ? findObject({
                data: consigneeList,
                value: row?.consigneeId,
                property: "cd",
                field: "value",
              })
            : "",
          loadingPort: row?.loadingPort,
          dischargingPort: row?.dischargingPort,
          vessel: row?.vesselName,
          brokerCompanyId: row?.broker
            ? findObject({
                data: brokerList,
                value: row?.broker,
                property: "value",
                field: "cd",
              })
            : "",
          brokerCompanyAbbr: row?.broker,
          shippingInfoHId: row?.shippingInfoHId,
          orderTypeCd: row?.orderTypeCd,
          carFamilyCd: row?.carFamilyCode,
        },
        container: [
          // {
          //   rPackage: [], // if empty choose delete all existing rPackage
          // },
        ], // if empty choose delete all existing container
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body?.shippingData,
        rule: [
          {
            field: "loadingPort",
            type: MessageType.EMPTY,
            key: ["Loading Port"],
          },
          {
            field: "dischargingPort",
            type: MessageType.EMPTY,
            key: ["Discharging Port"],
          },
          {
            field: "vessel",
            type: MessageType.EMPTY,
            key: ["Vessel Name"],
          },
          {
            field: "brokerCompanyId",
            type: MessageType.EMPTY,
            key: ["Broker"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return false;
      }
      const response = await updateSubInvoice(body);
      console.log(response);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };

  const processRowUpdate = async (newRow) => {
    console.log(newRow);
    let response;
    try {
      setMsgError([]);
      setMsgAlert([]);
      if (newRow.isNew) {
        response = await handleCreate(newRow);
      } else {
        response = await handleUpdate(newRow);
      }
      // throw to onProcessRowUpdate is error
      if (!response) return false;
      if (rows.length === 1 && ModeAction.ADD === mode) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowSelectionModel([]);
        setRowModesModel({});
        setRows([]);
      } else {
        setMode(ModeAction.VIEW);
        setRowSelectionModel([]);
        // const body = {
        //   exporterCode: form?.exporterId,
        //   importerCompany: form?.importerId,
        //   // importCode: form?.importerId,
        //   loadingPort: form?.loadingPort,
        //   dischargingPort: form?.dischargingPort,
        //   etdDt: form?.etd,
        //   vesselName: form?.vesselName,
        //   orderType: form?.orderTypeId,
        //   carFamilyCode: form?.carFamilyCode,
        //   [PAGINATION.PAGE_NUMBER]: pageNumber,
        //   [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
        // };

        const doneRefresh = await refreshRowsTable();
        if (!doneRefresh) return;
      }
      const updatedRow = { ...newRow, isNew: false };
      return updatedRow;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleCancelClick = async () => {
    const confirm = await confirmDialogCtx.success({
      type: "confirmCancel",
    });
    if (!confirm) {
      return;
    }

    setMsgError([]);
    // if (mode === ModeAction.ADD) {
    //   //To find maximum rowNumber
    //   const latestKey =
    //     !rows || rows.length === 0
    //       ? 1
    //       : Math.max(...rows.map((item) => item.rowNumber));

    //   setRowModesModel({
    //     ...rowModesModel,
    //     [latestKey]: { mode: GridRowModes.View, ignoreModifications: true },
    //   });

    //   setRows(rows.filter((row) => !row.isNew)); //Keep only not new

    //   // const editedRow = rows.find((row) => row.rowNumber === rows.length);
    //   // if (editedRow.isNew && editedRow.isNew) {
    //   //   setRows(rows.filter((row) => row.rowNumber !== rows.length));
    //   // }
    // } else {
    //   let tempRowModesModel = rowModesModel;

    //   const rowSelect = rowSelectionModel.map((val) => {
    //     tempRowModesModel = {
    //       ...tempRowModesModel,
    //       [val]: { mode: GridRowModes.View, ignoreModifications: true },
    //     };
    //   });

    //   rowSelect && setRowModesModel(tempRowModesModel);
    // }
    // setSearchButton(false);
    // setOnSearch(false);
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    setForm(DEFAULT_STATE_WDN95011);
    setOnOpen({ open: false });
  };
  const handleSaveClick = async () => {
    const saveConfirm = await confirmDialogCtx.success({ type: "confirmSave" });
    if (!saveConfirm) {
      return;
    }

    if (!onOpen?.isEdit) {
      let rowSelect = rows.filter((item) =>
        rowSelectionModel.includes(item.rowNumber)
      );

      // for create
      // console.log(rowSelect);

      handleCreate(rowSelect);

      // setRowModesModel({
      //   ...rowModesModel,
      //   [latestKey]: { mode: GridRowModes.View },
      // });
    } else {
      // for edit
      // let tempRowModesModel = rowModesModel;
      // const rowSelect = rowSelectionModel.map((val) => {
      //   console.log("rowSelect", val);
      //   tempRowModesModel = {
      //     ...tempRowModesModel,
      //     [val]: { mode: GridRowModes.View },
      //   };
      // });
      // rowSelect && setRowModesModel(tempRowModesModel);
    }

    // setMode(ModeAction.VIEW);
    // setRowSelectionModel([]);
  };

  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <DataGridTable
        id="table-container"
        mode={mode}
        setMode={setMode}
        rows={rows}
        onCellClick={false}
        checkboxSelection={true}
        processRowUpdate={processRowUpdate}
        column={{
          columns,
          columnVisibilityModel: {
            // [API_EXPORT_INVOICE_CREATION_INFO.SHIPPING_INFO_H_ID]: false,
            // [API_EXPORT_INVOICE_CREATION_INFO.VANNING_INFO_H_ID]: false,
            // [API_EXPORT_INVOICE_CREATION_INFO.IMPORTER_COMPANY]: false,
            // [API_EXPORT_INVOICE_CREATION_INFO.CONSIGNEE]: false,
          },
        }}
        rowSelection={{
          rowModesModel,
          setRowModesModel,
          rowSelectionModel,
          setRowSelectionModel,
        }}
        pagination={{
          pagination: pagination,
          pageNumber: pageNumber,
          handleChangePagination: handleChangePagination,
        }}
        isMultipleSelection={false}
      />
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <InputButton id="btn_save" value={"Save"} onClick={handleSaveClick} />
        <InputButton
          id="btn_cancel"
          value={"Cancel"}
          onClick={handleCancelClick}
        />
      </Grid>
      {/* <SubmitBar
        setOnSearch={setOnSearch}
        mode={onOpen?.isEdit ? ModeAction.EDIT : ModeAction.ADD}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
      /> */}
    </Box>
  );
}
