import React from "react";
import { Box, Grid, Link } from "@mui/material";
import WareHouseGraph from "./WareHouseGraph";
import LabelGraph from "./LabelGraph";

export default function GraphSection({ graphData, setOpenGraph }) {
  return (
    <Box sx={{ position: "relative" }}>
      <Link
        href="#"
        color="#000"
        onClick={() => setOpenGraph(false)}
        size="small"
        sx={{
          fontSize: "12px",
          position: "absolute",
          right: "0.5rem",
          top: "1.5rem",
        }}>
        Close
      </Link>
      <Grid
        container
        columns={{ xs: 6 }}
        justifyContent="space-evenly"
        alignItems="center"
        sx={{
          flexDirection: "column",
          height: 400,
          width: "auto",
          overflowX: "inherit",
          overflowY: "scroll",
        }}>
        {graphData?.map((wh, index) => (
          <Grid
            container
            key={index}
            item
            justifyContent="center"
            xs={4}
            id="graph-section"
            data-testid="graph-section">
            {wh.rPkgTypeList.map((rType, index) => (
              <WareHouseGraph
                key={index}
                dataSet={rType.dateQtyList}
                subtitle={rType.rPkgType}
                title={`${wh?.whCompanyAbbr}:${wh?.whImpExpCd}`}
              />
            ))}
          </Grid>
        ))}
        <Grid item>
          <LabelGraph />
        </Grid>
      </Grid>
    </Box>
  );
}
