import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { DataGridTable } from "components/UI";
import ButtonSubmit from "./ButtonSubmit";
//Util
import { messageDisplay, responseErrors } from "utils";
import { transformDataContainer } from "pages/Invoice/ShippingInformationMaintenanceScreen/hooks/transformData";
import { validationSearchForm } from "utils/validation";
// Service
import { useContainerSearchMutation } from "shared/services/invoice";
//type
import { MSG_TYPE, MessageType, ModeAction } from "state/enum";
import { COLUMN_SHIPPING_INFO } from "pages/Invoice/ShippingInformationMaintenanceScreen/constants/table";
import { COMMON_ENUM, FIRST_PAGE, PAGINATION } from "shared/constants";
import { isEmpty } from "lodash";
import sleep from "shared/hooks/Sleep";

export default function TableContainer(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    form,
    setForm,
    setOnSearch,
    getSumQty,
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
  } = props;
  // Api
  const searchContainer = useContainerSearchMutation();

  const columnGroupingModel = [
    {
      groupId: COLUMN_SHIPPING_INFO.NO,
      headerAlign: "center",
      children: [{ field: "rowNumber" }],
      headerClassName: "align-items-end",
    },
    {
      groupId: COLUMN_SHIPPING_INFO.SHIPPING_PLAN,
      headerAlign: "center",
      children: [
        { field: "planContainerNo" },
        { field: "planRModuleQty" },
        { field: "planRBoxDunQty" },
        { field: "planRRPkgDetail" },
      ],
      headerClassName: "table-header-group",
    },
    {
      groupId: COLUMN_SHIPPING_INFO.ACTUAL_VANNING,
      headerAlign: "center",
      children: [
        { field: "actualContainerNo" },
        { field: "actualRModuleQty" },
        { field: "actualRBoxDunQty" },
        { field: "actualRPkgDetail" },
        { field: "actualPackingMonth" },
        { field: "actualVanningPLant" },
        { field: "actualOrderType" },
      ],
      headerClassName: "table-header-group",
    },
    {
      groupId: COLUMN_SHIPPING_INFO.STATUS,
      headerAlign: "center",
      children: [{ field: "status" }],
      headerClassName: "align-items-end",
    },
  ];
  const classNameOfCell = {
    "& .theme-cell-row-stats-incomplete": {
      backgroundColor: "#FFD7D7",
    },
  };
  // custom style of row when statue is incomplete and on edit mode
  const handleClassNameRow = params => {
    if (params?.row?.status === "N") {
      return "custom-row";
    } else {
      return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
    }
  };

  const handleChangePagination = async (event, value) => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      setPageNumber(value);
      const ROW_PER_PAGE_TWENTY = 20;

      const body = {
        shippingInfoHID: form?.shippingHdrID,
        expCd: form?.exporterCd,
        impCompanyAbbr: form?.importerComp,
        etd: form?.etdDt, // DD/MM/YYYY
        impCd: "", // optional
        expCdSearch: "", // optional
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE_TWENTY,
      };
      const searchData = await searchContainer(body);
      // if (!searchData?.result?.data?.length) {
      //   return;
      // }
      const data = transformDataContainer(searchData?.result?.data);
      setForm(prev => ({ ...prev, rows: data }));
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      return;
    }
  };

  const handleCreate = async newRow => {
    try {
      let msg;
      const { isSuccess, errors } = validationSearchForm({
        data: newRow,
        rule: [
          {
            field: "planContainerNo",
            type: MessageType.EMPTY,
            key: ["Container No"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return { action: false, updated: {} };
      }
      // check duplicate container no
      const dupContainer = rows.find(row => row.planContainerNo === newRow.planContainerNo);
      if (dupContainer) {
        msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MSTD0039AERR",
          msg: [`Container No. = ${dupContainer?.planContainerNo}`],
        });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return { action: false, updated: {} };
      }

      const { sumRMQty, sumOTQty } = await getSumQty(newRow);
      sleep(3000);
      let notEqual = false;
      if (newRow.planContainerNo !== newRow?.actualContainerNo) notEqual = true;
      if (parseInt(newRow.planRModuleQty) !== sumRMQty) notEqual = true;
      if (parseInt(newRow.planRBoxDunQty) !== sumOTQty) notEqual = true;

      // check vanning status
      newRow.status = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;

      msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90042AINF",
        msg: [],
      });
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return { action: true, updated: newRow };
    } catch (error) {
      console.error(error);
      return { action: false, updated: {} };
    }
  };
  const handleUpdate = async row => {
    try {
      let msg;

      const { isSuccess, errors } = validationSearchForm({
        data: row,
        rule: [
          {
            field: "planContainerNo",
            type: MessageType.EMPTY,
            key: ["Container No"],
          },
        ],
      });

      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return { action: false, updated: {} };
      }
      // check duplicate container no
      const dupContainer = rows.find(
        ele => ele?.planContainerNo === row?.planContainerNo && ele?.rowNumber !== row?.rowNumber
      );
      if (dupContainer) {
        msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MSTD0039AERR",
          msg: [`Container No. = ${dupContainer?.planContainerNo}`],
        });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return { action: false, updated: {} };
      }

      // In case vanning status = Y , user change shipping container(plan) means planContainer != actualContainer
      // system will delete the row that user edit than add new row for new planContainer
      let newRow;
      if (row.status === COMMON_ENUM.VANNING_STATUS_COMPLETED) {
        if (!isEmpty(row?.actualContainerNo) && row?.planContainerNo !== row?.actualContainerNo) {
          newRow = {
            ...row,
            rowNumber: rows?.length + 1,
            planRBoxDunQty: "",
            planRModuleQty: "",
            actualContainerNo: "",
            actualRModuleQty: "",
            actualRBoxDunQty: "",
            actualPackingMonth: "",
            actualVanningPLant: "",
            actualOrderType: "",
            vanningInfoHId: "",
            shippingInfoDContId: "",
            renbanNo: "",
            status: COMMON_ENUM.VANNING_STATUS_INCOMPLETE,
            isNew: false,
          };
          // delete the row that user delete
          row["planContainerNo"] = "";
          row["planRModuleQty"] = "";
          row["planRBoxDunQty"] = "";
          row["status"] = COMMON_ENUM.VANNING_STATUS_INCOMPLETE;
          // tempRow = {
          //   ...row,
          //   planContainerNo: "",
          //   planRModuleQty: "",
          //   planRBoxDunQty: "",
          //   status: COMMON_ENUM.VANNING_STATUS_INCOMPLETE,
          // };
        } else {
          const { sumRMQty, sumOTQty } = await getSumQty(row);
          sleep(3000);
          // console.log(rPkgQty);
          let notEqual = false;
          if (row.planContainerNo !== row?.actualContainerNo) notEqual = true;
          // ! Condition below not correct yet
          if (parseInt(row.planRModuleQty) !== sumRMQty) notEqual = true;
          if (parseInt(row.planRBoxDunQty) !== sumOTQty) notEqual = true;
          // check vanning status
          row["status"] = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;
        }
      } else {
        // console.log(rPkgQty);
        const { sumRMQty, sumOTQty } = await getSumQty(row);
        let notEqual = false;
        if (row.planContainerNo !== row?.actualContainerNo) notEqual = true;
        // ! Condition below not correct yet
        if (parseInt(row.planRModuleQty) !== sumRMQty) notEqual = true;
        if (parseInt(row.planRBoxDunQty) !== sumOTQty) notEqual = true;
        row["status"] = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;
      }

      msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90043AINF",
        msg: [],
      });

      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return { action: true, updated: row, newContainer: newRow };
    } catch (error) {
      console.error(error);
      const err = responseErrors(error);
      setMsgError(err);
      return { action: false, updated: {} };
    }
  };
  const handleCopyActualData = async row => {
    try {
      // ! need sum qty first
      let tempRow;
      await getSumQty(row);
      if (row?.status === COMMON_ENUM.VANNING_STATUS_INCOMPLETE) {
        let notEqual = false;
        if (row.planContainerNo !== row?.actualContainerNo) notEqual = true;
        // ! Condition below not correct yet
        if (parseInt(row.planRModuleQty) !== parseInt(row?.actualRModuleQty)) notEqual = true;
        if (parseInt(row.planRBoxDunQty) !== parseInt(row?.actualRBoxDunQty)) notEqual = true;

        row["planContainerNo"] = row?.actualContainerNo;
        row["planRModuleQty"] = row?.actualRModuleQty;
        row["planRBoxDunQty"] = row?.actualRBoxDunQty;
        row["status"] = notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED;
        // tempRow = {
        //   ...row,
        //   planContainerNo: row?.actualContainerNo,
        //   planRModuleQty: row?.actualRModuleQty,
        //   planRBoxDunQty: row?.actualRBoxDunQty,
        //   // check vanning status
        //   status: notEqual ? COMMON_ENUM.VANNING_STATUS_INCOMPLETE : COMMON_ENUM.VANNING_STATUS_COMPLETED,
        // };
      }
      console.log(tempRow);
      const msg = messageDisplay({
        type: MSG_TYPE.INFO,
        code: "MDN90042AINF",
        msg: [],
      });
      setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return { action: true, updated: tempRow };
    } catch (error) {
      console.error(error);
      const err = responseErrors(error);
      setMsgError(err);
      return { action: false, updated: {} };
    }
  };
  const handleProcessRowUpdateError = useCallback(error => {
    console.error(` Error message from table:${error.message}`);
    // console.error(` children:${error.message}, severity: ${error}`);
  }, []);
  const processRowUpdate = async newRow => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      let updatedRow;
      let createNewRow;
      let response;
      if (newRow?.isNew) {
        const { action, updated } = await handleCreate(newRow);
        response = action;
        updatedRow = { ...updated, isNew: false };
      }
      // Copy Actual Operation
      else if (mode === ModeAction.COPY) {
        const { action, updated } = await handleCopyActualData(newRow);
        response = action;
        updatedRow = { ...updated };
      } else {
        const { action, updated, newContainer } = await handleUpdate(newRow);
        response = action;
        updatedRow = { ...updated };
        createNewRow = newContainer;
      }
      // throw to onProcessRowUpdate is error
      if (!response) return false;
      let tempRows;
      let isUpdateRow = false;
      let tempFormRows = form?.rows;
      tempRows = form?.rows?.map((row, index) => {
        if (row.rowNumber === updatedRow.rowNumber) {
          isUpdateRow = true;
          return { ...tempFormRows[index], ...updatedRow };
        }
        return row;
      });

      if (!!createNewRow) {
        setForm(prev => ({
          ...prev,
          rows: isUpdateRow ? [...tempRows, createNewRow] : [...tempFormRows, updatedRow, createNewRow],
        }));
        setRows(isUpdateRow ? [...tempRows, createNewRow] : [...tempFormRows, updatedRow, createNewRow]);
      } else {
        setForm(prev => ({ ...prev, rows: isUpdateRow ? tempRows : [...tempFormRows, updatedRow] }));
        setRows(isUpdateRow ? tempRows : [...tempFormRows, updatedRow]);
      }

      setMode(ModeAction.VIEW);
      setRowModesModel({});
      setRowSelectionModel([]);
      return updatedRow;
    } catch (error) {
      console.error(error);
      const err = responseErrors(error);
      setMsgError(err);
      return;
    }
  };
  return (
    <React.Fragment>
      <Box>
        <DataGridTable
          id="table-container-sub"
          mode={mode}
          setMode={setMode}
          rows={rows}
          onCellClick={false}
          onCellDoubleClick={false}
          isMultipleSelection={true}
          checkboxSelection={true}
          rowHeight={45}
          tableHeight="400px"
          classNameOfCell={classNameOfCell}
          handleClassNameRow={handleClassNameRow}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          handleChangePagination={handleChangePagination}
          column={{
            columns,
            columnGroupingModel,
            columnVisibilityModel: { shippingInfoDContId: false, vanningInfoHId: false, renbanNo: false },
          }}
          rowSelection={{ rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel }}
          onPagination={false}
          pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
        />
      </Box>
      <ButtonSubmit
        mode={mode}
        setMode={setMode}
        rows={rows}
        setRows={setRows}
        rowModesModel={rowModesModel}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        setOnSearch={setOnSearch}
      />
    </React.Fragment>
  );
}
