import React from "react";
import { Box, Divider, Grid } from "@mui/material";
export default function LabelGraph() {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <strong style={{ color: "#d32f2f" }}>-------- </strong>
          Max
        </Grid>
        <Grid item>
          <strong style={{ color: "#000000" }}>-------- </strong>
          Ending Balance + (Confirmed + Allocated Status)
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item>
          <strong style={{ color: "#43a047" }}>-------- </strong>
          Min
        </Grid>
        <Grid item>
          <strong style={{ color: "#2196f3" }}>-------- </strong>
          Updated Ending
        </Grid>
      </Grid>
    </Box>
  );
}
