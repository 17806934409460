import React, { Fragment } from "react";
// Component
import { Grid } from "@mui/material";
import { InputButton, LinkFile } from "components/UI";
// Hook
import { useConfirmDialogContext } from "context/confirmDialogContext";
// Service
import { useDownloadTemplateMutation, useMappingCancelMutation } from "shared/services/inventory-forecast";
//UTIL
import { messageDisplay, responseDownloadFileErrors, responseErrors, responseSuccess } from "utils";
// Constant & Type
import { API_SHIPMENT, BUTTON_VALUE, COMMON_ENUM, TEMPLATE_FILE_CODE } from "shared/constants";
import { ModeAction, MSG_TYPE } from "state/enum";
import { getLocalDate } from "utils/init-config-date";
import { userProfile } from "constant";
import dayjs from "dayjs";
export default function ActionTableBar(props) {
  const confirmDialogCtx = useConfirmDialogContext();
  const {
    form,
    mode,
    setMode,
    setForm,
    columns,
    refresh,
    rows,
    setRows,
    setOnSearch,
    setMsg: { setMsgError, setMsgAlert },
    buttonState: { addBtn, editBtn, cancelShipmentBtn },
    dataList: { exporterList, importerList, rPkgList },
    rowSelection: { selectedRowKeys, setSelectedRowKeys, selectedRows, setSelectedRows },
  } = props;
  const exportTemplateGrouping = useDownloadTemplateMutation();
  const cancelShipment = useMappingCancelMutation();
  const handleAddBtn = () => {
    try {
      setMsgAlert([]);
      setMsgError([]);
      setForm(old => ({
        ...old,
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.FILE_NAME]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
          [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
        },
        [API_SHIPMENT.FILE]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
          [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
        },
      }));
      const firstRow = rows.find(obj => obj.no === 1);
      // const formDt = getLocalDate(form.forecastMonth, "MM/YYYY");
      const formDt = getLocalDate(form.forecastMonth, "MM/YYYY");
      // check first row
      // check row forecast month is before form forecast month
      if (dayjs(firstRow.forecastMonth, "MM/YYYY").isBefore(formDt, "month")) {
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN92024AERR",
          msg: [`${firstRow?.shipmentCd}(ETD=${firstRow?.etdDt})`],
        });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return;
      } else if (!firstRow?.orgNcvFileName) {
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN92024AERR",
          msg: [`${firstRow?.shipmentCd}(ETD=${firstRow?.etdDt})`],
        });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return;
      }

      let maxNo = -1;
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.ADD);
      setOnSearch(true);
      setForm(old => ({
        ...old,
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
        [API_SHIPMENT.FILE_NAME]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
          [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
        },
        [API_SHIPMENT.FILE]: {
          [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
          [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
          [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
        },
      }));
      const field = {};
      columns.forEach(column => {
        if (column.accessorKey === API_SHIPMENT.ROW_NUMBER) {
          maxNo = rows?.length;
          field[column.accessorKey] = rows.length ? maxNo + 1 : maxNo;
        } else {
          field[column.accessorKey] = "";
        }
      });
      const planField = {
        ...field,
        rowSpan: 2,
        [API_SHIPMENT.FORECAST_MONTH]: form?.forecastMonth,
        [API_SHIPMENT.EXPORTER]: form?.exporterId,
        // [API_SHIPMENT.EXPORTER]: form?.exporterId
        //   ? exporterList.find(v => v.companyAbbr === form?.exporterId).impExpCd
        //   : "",
        [API_SHIPMENT.IMPORTER]: form?.importerId
          ? importerList.find(v => v.companyId === form?.importerId).companyAbbr
          : "",
        [API_SHIPMENT.PLAN_ACTL]: "Plan",
        isNew: true,
      };
      setRows(prev => [
        ...prev,
        {
          ...planField,
        },
        {
          ...field,
          [API_SHIPMENT.FORECAST_MONTH]: form?.forecastMonth,
          [API_SHIPMENT.EXPORTER]: form?.exporterId,
          [API_SHIPMENT.IMPORTER]: form?.importerId
            ? importerList.find(v => v.companyId === form?.importerId).companyAbbr
            : "",
          [API_SHIPMENT.PLAN_ACTL]: "Actual",
          isNew: true,
        },
      ]);
      const maxNoModel = rows.length ? maxNo + 1 : maxNo;
      setSelectedRows(prev => ({
        ...prev,
        [maxNoModel]: true,
      }));
      setSelectedRowKeys(prev => [...prev, { ...planField }]);
      return;
    } catch (error) {
      console.error(error);
    }
  };
  const handleEditBtn = () => {
    setMsgError([]);
    setMsgAlert([]);
    setForm(old => ({
      ...old,
      [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
      [API_SHIPMENT.NCV_FILE_UPLOAD]: "",
      [API_SHIPMENT.FILE_NAME]: {
        [API_SHIPMENT.ORG_ACTL_FILE_NAME]: "",
        [API_SHIPMENT.ORG_NCV_FILE_NAME]: "",
        [API_SHIPMENT.ORG_BL_FILE_NAME]: "",
      },
      [API_SHIPMENT.FILE]: {
        [API_SHIPMENT.ORG_ACTL_FILE_NAME]: {},
        [API_SHIPMENT.ORG_NCV_FILE_NAME]: {},
        [API_SHIPMENT.ORG_BL_FILE_NAME]: {},
      },
    }));

    const selectNo = Object.keys(selectedRows)[0];
    if (Number(selectNo) === 0) {
      setMode(ModeAction.EDIT);
      return;
    } else if (Number(selectNo) === 1) {
      const firstRow = rows.find(obj => obj.no === 0);
      if (firstRow.cancelFlag === "Y") {
        setMode(ModeAction.EDIT);
        return;
      }
      if (!firstRow?.orgNcvFileName) {
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN92024AERR",
          msg: [`${firstRow?.shipmentCd}(ETD=${firstRow?.etdDt})`],
        });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return;
      } else {
        setMode(ModeAction.EDIT);
        return;
      }
    } else {
      const beforeRow = rows.find((obj, index) => index + 1 === Number(selectNo) - 1);
      // const selectRow = rows.find(obj => obj?.no === Number(selectNo));
      if (!beforeRow?.orgNcvFileName || beforeRow.shipmentSts === "Cancel") {
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN92024AERR",
          msg: [`${beforeRow?.shipmentCd}(ETD=${beforeRow?.etdDt})`],
        });
        setMsgError([msg]);
        window.scrollTo(0, 0);
        return;
      } else {
        setMode(ModeAction.EDIT);
        return;
      }
    }
  };
  const handleCancelShipmentBtn = async () => {
    setMsgError([]);
    setMsgAlert([]);

    const confirm = await confirmDialogCtx.success({
      type: "FORECAST_SHIPMENT_CONFIRM_CANCEL",
    });
    if (!confirm) return;

    try {
      const selectedNo = Object.keys(selectedRows)[0];
      const data = rows?.find((v, index) => index === Number(selectedNo));
      console.log(data);

      const shipmentId = data?.shipmentSummaryHId;
      const body = {
        [API_SHIPMENT.DATA_OWNER]: form?.dataOwner,
        [API_SHIPMENT.COMPANY]: form?.companyAbbr,
        [API_SHIPMENT.COMPANY_ABBR]: form?.companyAbbr,
        [API_SHIPMENT.MANUAL_UPLOAD_INV_FLAG]: COMMON_ENUM.FLAG_YES,
        [API_SHIPMENT.UPDATE_DATE]: data?.updateDt,
        [API_SHIPMENT.UPDATE_BY]: form?.userId,
      };
      const response = await cancelShipment(shipmentId, body);
      const msg = responseSuccess(response);
      // const msg = messageDisplay({ type: MSG_TYPE.INFO, code: "MDN90016AINF", msg: [""] });
      setMsgAlert([msg]);
      await refresh();
      setMode(ModeAction.VIEW);
      setSelectedRowKeys([]);
      setSelectedRows({});
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return false;
    }
  };
  const handleDownloadTemplate = async () => {
    setMsgAlert([]);
    setMsgError([]);
    try {
      const infoFile = TEMPLATE_FILE_CODE.find(v => v.value === "Actual Upload");
      const body = { [API_SHIPMENT.DATA_OWNER]: userProfile.dataOwner, cd: infoFile?.cd };
      // const filename = infoFile?.fileName;
      await exportTemplateGrouping(body);
    } catch (error) {
      const err = responseDownloadFileErrors(error);
      setMsgError([err]);
      // setMsgError([errors]);
      window.scroll(0, 0);
      return;
    }
  };
  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton
            id="btn_cancel"
            value={"Cancel Shipment"}
            disabled={cancelShipmentBtn}
            onClick={handleCancelShipmentBtn}
          />
          <InputButton id="btn_edit" value={BUTTON_VALUE.EDIT} disabled={editBtn} onClick={handleEditBtn} />
          <InputButton id="btn_add" value={"Add Shipment"} disabled={addBtn} onClick={handleAddBtn} />
          <LinkFile value={"Actual Upload Template"} onClick={handleDownloadTemplate} />
        </Grid>
      </Grid>
    </Fragment>
  );
}
