import React, { useState, Fragment, useEffect } from "react";
import { List, ListItemButton, ListItemText, Collapse, useTheme } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuItem from "components/UI/MenuItem/MenuItem";
import { useLocation } from "react-router-dom";
import { isUndefined } from "lodash";
export default function MultipleLevel(props) {
  const { item, depthStep = 2, depth = 0 } = props;
  const { pathname } = useLocation();
  const theme = useTheme();
  const { subItems: children } = item;

  const [collapsed, setCollapsed] = useState(false);
  const handleClick = () => {
    setCollapsed(prev => !prev);
  };

  useEffect(() => {
    if (children.length > 0) {
      children.forEach(v => {
        if (!isUndefined(v?.subItems) && v?.subItems > 0) {
          setCollapsed(true);
          return true;
        }
        return false;
      });

      // if (depthOne?.subItems.length > 0) {
      //   setCollapsed(true);
      // } else {
      //   setCollapsed(false);
      // }
      // Normal Stage
      if (children.find(v => v.path === pathname)) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    }
  }, [children, pathname]);

  return (
    <Fragment>
      <List disablePadding dense sx={{ display: "block" }}>
        <ListItemButton
          onClick={handleClick}
          sx={{
            borderRadius: 2,
            margin: 1,
            "& .MuiTypography-root": { fontWeight: "bold", fontSize: "16px" },
          }}>
          <ListItemText primary={item?.title} sx={{ ml: depth * depthStep }} />
          {collapsed ? (
            <ExpandLess
              sx={{
                color: theme.palette.primary.light,
                "&:hover": { color: "red" },
              }}
            />
          ) : (
            <ExpandMore sx={{ color: theme.palette.primary.light }} />
          )}
        </ListItemButton>
        <Collapse in={collapsed} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, key) => (
              <MenuItem item={child} key={key} depth={depth + 1} depthStep={depthStep} />
            ))}
          </List>
        </Collapse>
      </List>
    </Fragment>
  );
}
