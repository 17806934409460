import { isEmpty } from "lodash";
import { API_INVOICE_SHIPPING_INFO } from "shared/constants/api-name/invoice/shipmentInfoMain";
import { findObject } from "utils";

const transformShippingData = dataList => {
  const data =
    dataList.map((item, index) => ({
      no: index + 1,
      [API_INVOICE_SHIPPING_INFO.ROW_NUMBER]: index + 1,
      [API_INVOICE_SHIPPING_INFO.EXPORTER_CODE]: item?.expCd,
      [API_INVOICE_SHIPPING_INFO.EXPORTER_COUNTRY]: item?.expCountryDesc,
      [API_INVOICE_SHIPPING_INFO.EXPORTER_COUNTRY_CODE]: item?.expCountryCd,
      [API_INVOICE_SHIPPING_INFO.IMPORTER_CODE]: item?.impCd,
      [API_INVOICE_SHIPPING_INFO.IMPORTER_COMPANY]: item?.impCompanyAbbr,
      [API_INVOICE_SHIPPING_INFO.IMPORTER_COMPANY_ID]: item?.impCompanyId,
      [API_INVOICE_SHIPPING_INFO.ETD_DATE]: item?.etd,
      [API_INVOICE_SHIPPING_INFO.CONSIGNEE]: item?.consigneeExpImpCd,
      [API_INVOICE_SHIPPING_INFO.CONSIGNEE_ID]: item?.consigneeCompanyId,
      [API_INVOICE_SHIPPING_INFO.LOADING_PORT]: item?.loadingPortDesc,
      [API_INVOICE_SHIPPING_INFO.DISCHARGING_PORT]: item?.dischargingPortDesc,
      [API_INVOICE_SHIPPING_INFO.VESSEL_NAME]: item?.vesselName,
      [API_INVOICE_SHIPPING_INFO.BROKER]: item?.brokerCompanyAbbr,
      [API_INVOICE_SHIPPING_INFO.BROKER_ID]: item?.brokerCompanyId,
      [API_INVOICE_SHIPPING_INFO.CAR_FAMILY_CODE]: item?.carFamilyCd,
      [API_INVOICE_SHIPPING_INFO.ORDER_TYPE]: item?.orderType,
      [API_INVOICE_SHIPPING_INFO.ORDER_TYPE_CODE]: item?.orderTypeCd,
      [API_INVOICE_SHIPPING_INFO.VANNING_STATUS]: item?.vanningStatus,
      [API_INVOICE_SHIPPING_INFO.AIR_SHIPMENT]: item?.airShipment,
      [API_INVOICE_SHIPPING_INFO.SHIPPING_INFO_H_ID]: item?.shippingInfoHId,
      [API_INVOICE_SHIPPING_INFO.UPDATE_DATE]: item?.updateDt,
      [API_INVOICE_SHIPPING_INFO.UPDATE_BY]: item?.updateBy,
      // get from update container in temp table
      [API_INVOICE_SHIPPING_INFO.SHIPPING_SESSION_ID]: item?.sessionId,
    })) ?? [];
  return data;
};

const transformDataContainer = (containerList, orderTypeList, contdSession) => {
  const data =
    containerList.map((item, index) => ({
      no: index + 1,
      [API_INVOICE_SHIPPING_INFO.ROW_NUMBER]: index + 1,
      [API_INVOICE_SHIPPING_INFO.PLAN_CONTAINER_NO]: item?.planContainerNo,
      [API_INVOICE_SHIPPING_INFO.PLAN_RM_QTY]: item?.planRModuleQty,
      [API_INVOICE_SHIPPING_INFO.PLAN_OT_QTY]: item?.planRBoxDunQty,
      [API_INVOICE_SHIPPING_INFO.PLAN_DETAIL_BTN]: "P",
      [API_INVOICE_SHIPPING_INFO.ACTUAL_CONTAINER_NO]: item?.actualContainerNo,
      [API_INVOICE_SHIPPING_INFO.ACTUAL_RM_QTY]: item?.actualRModuleQty,
      [API_INVOICE_SHIPPING_INFO.ACTUAL_OT_QTY]: item?.actualRBoxDunQty,
      [API_INVOICE_SHIPPING_INFO.RENBAN_NO]: item?.renbanNo,
      [API_INVOICE_SHIPPING_INFO.ACTUAL_DETAIL_BTN]: "A",
      [API_INVOICE_SHIPPING_INFO.ACTUAL_PACKING_MONTH]: item?.packingMonth,
      [API_INVOICE_SHIPPING_INFO.ACTUAL_VANNING_PLANT]: item?.vanningPlant,
      [API_INVOICE_SHIPPING_INFO.ACTUAL_ORDER_TYPE]: !item?.orderType
        ? ""
        : findObject({ data: orderTypeList, value: item?.orderType, property: "cd", field: "value" }),
      [API_INVOICE_SHIPPING_INFO.CONTAINER_STATUS]: item?.status,
      [API_INVOICE_SHIPPING_INFO.SHIPPING_INFO_D_CONT_ID]: item?.shippingInfoDContId,
      [API_INVOICE_SHIPPING_INFO.VANNING_INFO_H_ID]: item?.vanningInfoHId,
      [API_INVOICE_SHIPPING_INFO.CONT_SESSION_ID]: item?.sessionId,
    })) ?? [];
  return data;
};

const transformDataRPkg = (dataList, rPackageOwnerList, rPkgSession) => {
  try {
    const data =
      dataList.map((item, index) => ({
        no: index + 1,
        [API_INVOICE_SHIPPING_INFO.ROW_NUMBER]: index + 1,
        [API_INVOICE_SHIPPING_INFO.R_PKG_OWNER]: item?.rPkgOwnerCompAbbr,
        [API_INVOICE_SHIPPING_INFO.R_PKG_OWNER_ID]: findObject({
          data: rPackageOwnerList,
          value: item?.rPkgOwnerCompAbbr,
          property: "rPkgOwnerCompAbbr",
          field: "rPkgOwnerCompId",
        }),
        [API_INVOICE_SHIPPING_INFO.R_PKG_TYPE_ID]: item?.rPkgTypeId,
        [API_INVOICE_SHIPPING_INFO.R_PKG_TYPE]: item?.rPkgType,
        [API_INVOICE_SHIPPING_INFO.R_PKG_PLAN_QTY]: item?.qty,
        [API_INVOICE_SHIPPING_INFO.R_PKG_ACTUAL_QTY]: item?.actQty,
        [API_INVOICE_SHIPPING_INFO.GROUP_CD]: item?.groupCd,
        [API_INVOICE_SHIPPING_INFO.SHIPPING_INFO_D_CONT_ID]: item?.shppingInfoDContId,
        [API_INVOICE_SHIPPING_INFO.SHIPPING_INFO_D_PKG_ID]: item?.shppingInfoDPkgId,
        [API_INVOICE_SHIPPING_INFO.R_PKG_SESSION_ID]: rPkgSession,
      })) ?? [];
    return data;
  } catch (error) {
    console.error(error);
  }
};
const formatRPkgForUpdateData = rPackageList => {
  if (!rPackageList?.length) {
    return [];
  }

  const rPackage = rPackageList?.map(rPkg => ({
    rPkgOwnerCompAbbr: rPkg?.rPkgOwner,
    rPkgType: rPkg?.rPkgType,
    rPkgTypeId: rPkg?.rPkgTypeId,
    qty: rPkg?.planQty,
    actQty: rPkg?.planQty,
    groupCd: rPkg?.groupCd,
    shppingInfoDContId: rPkg?.shippingInfoDContId,
    shppingInfoDPkgId: rPkg?.shippingInfoDPkgId,
  }));
  return rPackage;
};
const formatContainerForUpdateData = containerList => {
  try {
    if (!containerList?.length) {
      return [];
    }
    let tempContainer;

    const newCont = containerList.filter(container => !isEmpty(container?.planContainerNo));
    const data = newCont.map((item, index) => {
      tempContainer = {
        shippingInfoDContId: item?.shippingInfoDContId, //option
        vanningInfoHId: item?.vanningInfoHId, //option
        planContainerNo: item?.planContainerNo,
        planRModuleQty: item?.planRModuleQty,
        planRBoxDunQty: item?.planRBoxDunQty,
        actualContainerNo: item?.actualContainerNo,
        actualRModuleQty: item?.actualRModuleQty,
        actualRBoxDunQty: item?.actualRBoxDunQty,
        renbanNo: item?.renbanNo, //option
        packingMonth: item?.actualPackingMonth, //option
        vanningPlant: item?.actualVanningPLant, //option
        orderType: item?.actualOrderType, //option
        status: item?.status,
        rPackage: formatRPkgForUpdateData(item?.rPackage),
      };
      return tempContainer;
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
const transformContdUpdateTemp = (containerList, sessionId) => {
  try {
    if (!containerList?.length) {
      return {
        sesionnId: !sessionId ? "" : sessionId,
        data: [],
      };
    }
    // const newCont = containerList.filter(container => !isEmpty(container?.planContainerNo));
    const data = containerList.map((item, index) => ({
      sesionnId: item?.sessionId,
      shippingInfoDContId: item?.shippingInfoDContId, //option
      vanningInfoHId: item?.vanningInfoHId, //option
      planContainerNo: item?.planContainerNo,
      planRModuleQty: item?.planRModuleQty,
      planRBoxDunQty: item?.planRBoxDunQty,
      actualContainerNo: item?.actualContainerNo,
      actualRModuleQty: item?.actualRModuleQty,
      actualRBoxDunQty: item?.actualRBoxDunQty,
      renbanNo: item?.renbanNo, //option
      packingMonth: item?.actualPackingMonth, //option
      vanningPlant: item?.actualVanningPLant, //option
      orderType: item?.actualOrderType, //option
      status: item?.status,
    }));
    return {
      sesionnId: !sessionId ? "" : sessionId,
      data,
    };
  } catch (error) {
    console.error(error);
  }
};
const transformRPKgUpdateTemp = (rPackageList, sesionnId) => {
  if (!rPackageList?.length) {
    return { sesionnId: !sesionnId ? "" : sesionnId, data: [] };
  }

  const data = rPackageList?.map(rPkg => ({
    rPkgOwnerCompAbbr: rPkg?.rPkgOwner,
    rPkgType: rPkg?.rPkgType,
    rPkgTypeId: rPkg?.rPkgTypeId,
    qty: rPkg?.planQty,
    actQty: rPkg?.planQty,
    shppingInfoDContId: rPkg?.shippingInfoDContId,
    shppingInfoDPkgId: rPkg?.shippingInfoDPkgId,
    groupCd: rPkg?.groupCd,
  }));
  return { sesionnId: !sesionnId ? "" : sesionnId, data };
};
export {
  transformShippingData,
  transformDataContainer,
  transformDataRPkg,
  formatContainerForUpdateData,
  transformContdUpdateTemp,
  transformRPKgUpdateTemp,
  // formatRPkgForUpdateDate,
};
